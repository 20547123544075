<template>
  <v-dialog
      v-model="dialog"
      max-width="900px"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} enrol buttons</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  v-model="enrolOption.name"
                  :error="$v.enrolOption.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.enrolOption.name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-select
                  v-model="enrolOption.exam_type"
                  :items="examTypes"
                  item-text="name"
                  item-value="value"
                  :error="$v.enrolOption.exam_type.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Exam Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.enrolOption.exam_type.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  v-model="enrolOption.url"
                  :error="$v.enrolOption.url.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  URL <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.enrolOption.url.$error">This information is required</span>
              <span class="text-danger" v-if="errors.url" >* {{ errors.url[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" v-if="currentUser.access_type ==='federal'">
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="scores"
                  v-model="enrolOption.score_ids"
                  multiple
                  small-chips
                  deletable-chips
                  clearable
                  outlined
                  dense
                  label="Scores"
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="selectAllScores"
                  >
                    <v-list-item-action>
                      <v-icon :color="enrolOption.score_ids.length > 0 ? 'indigo darken-4' : ''">
                        {{ iconScore }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="currentUser.access_type ==='federal'">
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="scores"
                  v-model="enrolOption.hidden_score_ids"
                  multiple
                  small-chips
                  deletable-chips
                  clearable
                  outlined
                  dense
                  label="Visible on Scores"
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="selectAllHiddenScores"
                  >
                    <v-list-item-action>
                      <v-icon :color="enrolOption.hidden_score_ids.length > 0 ? 'indigo darken-4' : ''">
                        {{ iconHiddenScore }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="categories"
                  v-model="enrolOption.category_ids"
                  multiple
                  small-chips
                  deletable-chips
                  clearable
                  outlined
                  dense
              >
                <template v-slot:label>
                  Select Exam Categories that fall under this Enrolment
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="selectAllCategories"
                  >
                    <v-list-item-action>
                      <v-icon :color="enrolOption.category_ids.length > 0 ? 'indigo darken-4' : ''">
                        {{ iconCategory }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>

            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="instruments"
                  v-model="enrolOption.instrument_ids"
                  multiple
                  small-chips
                  deletable-chips
                  clearable
                  outlined
                  dense
              >
                <template v-slot:label>
                  Select Exam Instruments that fall under this Enrolment
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="selectAllInstruments"
                  >
                    <v-list-item-action>
                      <v-icon :color="enrolOption.instrument_ids.length > 0 ? 'indigo darken-4' : ''">
                        {{ iconInstrument }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>

            </v-col>

            <v-col cols="12" md="12">
              Popup Info <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="enrolOption.description" >
              </ckeditor>
              <span class="text-danger" v-if="$v.enrolOption.description.$error">This information is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="6" md="6">
              Status
              <v-switch
                  v-model="enrolOption.is_active"
                  :label="enrolOption.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            x-large
            text
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="loading"
            color="btn btn-primary"
           x-large
            dark
            @click="createOrUpdate()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ScoreService from "@/services/score/score/ScoreService";
import {required} from "vuelidate/lib/validators";
import EnrolOptionService from "@/services/enrol-option/EnrolOptionService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import CategoryService from "@/services/product/category/CategoryService";

const enrolOption = new EnrolOptionService();
const score = new ScoreService();
const category = new CategoryService();
const instrument = new InstrumentService();

export default {
  name: "CreateAndUpdate",
  validations: {
    enrolOption: {
      name: { required },
      url: { required },
      exam_type: { required },
      description: { required },
      is_active: { required }
    }
  },
  data() {
    return {
      title: '',
      dialog: false,
      loading: false,
      edit: false,
      errors: [],
      categories: [],
      instruments: [],
      scores: [],
      currentUser: {},
      enrolOption: {
        name: '',
        url: '',
        exam_type: '',
        score_ids: [],
        hidden_score_ids: [],
        category_ids: [],
        instrument_ids: [],
        description: '',
        is_active: true,
      },
      examTypes: [
        { name: 'Online Exam', value: 'online_exam'},
        { name: 'Online Course', value: 'online_course'},
        { name: 'Practical Exam', value: 'practical_exam'},
      ],
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
    }
  },
  mounted() {
    this.getAllScores();
    this.getAllCategories();
    this.getAllInstruments();
    this.getCurrentUser();
  },
  computed: {
    selectAllCategory () {
      return this.enrolOption.category_ids.length === this.categories.length
    },
    selectedCategory () {
      return this.enrolOption.category_ids.length > 0 && !this.selectAllCategory
    },
    iconCategory () {
      if (this.selectAllCategory) return 'mdi-close-box'
      if (this.selectedCategory) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    selectAllScore () {
      return this.enrolOption.score_ids.length === this.scores.length
    },
    selectedScore () {
      return this.enrolOption.score_ids.length > 0 && !this.selectAllScore
    },
    iconScore () {
      if (this.selectAllScore) return 'mdi-close-box'
      if (this.selectedScore) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    selectAllHiddenScore () {
      return this.enrolOption.hidden_score_ids.length === this.scores.length
    },
    selectedHiddenScore () {
      return this.enrolOption.hidden_score_ids.length > 0 && !this.selectAllHiddenScore
    },
    iconHiddenScore () {
      if (this.selectAllHiddenScore) return 'mdi-close-box'
      if (this.selectedHiddenScore) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    selectAllInstrument () {
      return this.enrolOption.instrument_ids.length === this.instruments.length
    },
    selectedInstrument () {
      return this.enrolOption.instrument_ids.length > 0 && !this.selectAllInstrument
    },
    iconInstrument () {
      if (this.selectAllInstrument) return 'mdi-close-box'
      if (this.selectedInstrument) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    closeDialog() {
      this.dialog=false;
    },
    editEnrolOption(enrolOption) {
      this.title = 'Update';
      this.dialog = true;
      this.edit = true;
      this.enrolOption = enrolOption;
      if(this.enrolOption.instrument_ids == null) {
        this.enrolOption.instrument_ids = [];
      }
      if(this.enrolOption.category_ids == null) {
        this.enrolOption.category_ids = [];
      }
      if(this.enrolOption.score_ids == null) {
        this.enrolOption.score_ids = [];
      }
      if(this.enrolOption.hidden_score_ids == null) {
        this.enrolOption.hidden_score_ids = [];
      }
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    getAllScores() {
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllCategories(){
      category
          .all()
          .then(response => {
            this.categories = response.data.categories;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllInstruments(){
      instrument
          .all()
          .then(response => {
            this.instruments = response.data.instruments;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    selectAllCategories () {
      this.$nextTick(() => {
        if (this.selectAllCategory) {
          this.enrolOption.category_ids = []
        } else {
          this.enrolOption.category_ids = [];
          this.categories.forEach(category => {
            this.enrolOption.category_ids.push(category.id);
          });
        }
      })
    },
    selectAllScores () {
      this.$nextTick(() => {
        if (this.selectAllScore) {
          this.enrolOption.score_ids = []
        } else {
          this.enrolOption.score_ids = [];
          this.scores.forEach(score => {
            this.enrolOption.score_ids.push(score.id);
          });
        }
      })
    },
    selectAllHiddenScores () {
      this.$nextTick(() => {
        if (this.selectAllHiddenScore) {
          this.enrolOption.hidden_score_ids = []
        } else {
          this.enrolOption.hidden_score_ids = [];
          this.scores.forEach(score => {
            this.enrolOption.hidden_score_ids.push(score.id);
          });
        }
      })
    },
    selectAllInstruments () {
      this.$nextTick(() => {
        if (this.selectAllInstrument) {
          this.enrolOption.instrument_ids = []
        } else {
          this.enrolOption.instrument_ids = [];
          this.instruments.forEach(instrument => {
            this.enrolOption.instrument_ids.push(instrument.id);
          });
        }
      })
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (this.edit) this.update();
      }
    },

    update() {
      this.loading = true;
      enrolOption
          .update(this.enrolOption.id,this.enrolOption)
          .then(response => {
            this.$snotify.success("Enrol button updated");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.dialog = true;
      this.errors = [];
      this.$v.$reset();
      this.enrolOption = {
        name: '',
        url: '',
        exam_type: '',
        score_ids: [],
        hidden_score_ids: [],
        category_ids: [],
        instrument_ids: [],
        description: '',
        is_active: true,
      }
    },
  }
}
</script>

<style scoped>

</style>
